<template>
  <div class="user-logged">
    <div class="balance">
      <span>{{ $formatAmountUnit(store.currentUser.balance, 1) }}</span>
      <BaseImg
        class="icon-amount"
        alt="user avatar"
        :src="`${PATH_IMAGES}icon/amount.webp`"
      />
    </div>
    <BaseButton
      id="header-deposit-btn"
      class="btn-deposit base-button--btn-secondary"
      @click="navigateTo(ACCOUNT_URLS.TAB_DEOPSIT)"
    >
      Nạp
    </BaseButton>
    <div class="group-avatar" @click="openMenuAccount()">
      <BaseImg
        class="avatar"
        alt="user avatar"
        :src="`/assets/images/components/desktop/avatar/${store.currentUser?.avatar}.webp`"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from '~/store/app'
import { PATH_IMAGES } from '~/constants/path'
import BaseButton from '~/components/common/base-button.vue'
import BaseImg from '~/components/common/base-img.vue'
import { ACCOUNT_URLS } from '~/config/page-url'
const { $pinia, $formatAmountUnit } = useNuxtApp()
const store = useAppStore($pinia)
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/header/user-logged.scss" />
